@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
        font-family: 'Caveat';
        src: url('../public/fonts/1/Caveat-Bold.woff') format('woff');
    }
}

:root {
    --backgroundColor: rgba(80, 80, 80, 0.16);
    --backgroundColorSecondary: #FFFC94;
    --textPrimaryColor: #2A2A2A;

    --headlineBig: clamp(3rem, 5vw, 5rem);
    --headlineSmall: clamp(1rem, 1vw, 2rem);
}

@layer base {
    @font-face {
        font-family: 'maca';
        src: url('../public/fonts/2/Macondo-Regular.woff') format('woff');
    }
}

@layer base {
    @font-face {
        font-family: 'mikh';
        src: url('../public/fonts/3/CARBOL__.woff2') format('woff2');
    }
}

@layer base {
    @font-face {
        font-family: 'anton';
        src: url('../public/fonts/4/Anton-Regular.ttf') format('woff2');
    }
}

html {
    scroll-behavior: smooth;
}

body {
    height: 100%;
    margin: 0;
    background-color: #000000;
    overflow: auto;
}

:root {
    --light: rgb(23, 23, 23);
    --dark: transparent;
}

.wrap {
    background: var(--light);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h1 {
    text-align: center;
}

/*.section{*/
/*  height:100vh;*/
/*}*/
.color-dark {
    color: transparent;
}

/* canvas{
 width: 100vw !important;
 height: 100vh !important;
} */
.polygon {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%) !important;

}

.polygon-wrap {
    /*filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.5));*/
}


/*.reveal {*/
/*  !*background: var(--backgroundColor);*!*/
/*  padding-left: 10%;*/
/*  padding-right: 10%;*/
/*}*/

.reveal-text {
    /*font-weight: 500;*/
    /*font-size: var(--headlineBig);*/
    /*line-height: var(--headlineBig);*/
    color: var(--backgroundColor);
    filter: drop-shadow(0 0 0.06rem rgb(112, 112, 112));
}

/*.spacing-small {*/
/*  height: 20vh;*/
/*}*/

/*.spacing {*/
/*  height: 70vh;*/
/*}*/

/*@media screen and (max-width: 500px) {*/
/*  .reveal {*/
/*    padding-left: 10px;*/
/*  }*/
/*}*/


.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 206px;
    overflow-x: hidden;
}

.track {
    position: absolute;
    white-space: nowrap;
}

.track > h1 {
    margin: 20px 0;
    font-size: 6rem;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ffffff;
    text-transform: uppercase;
}


.button {
    /*width: 7em;*/
    padding: 0.3em 0;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    color: #fff;
    border: 1px solid currentColor;
    background: transparent;
    border-radius: 1.5em;
    /*line-height: 1;*/
    font-size: 2.5rem;
    text-transform: uppercase;
    cursor: pointer;
}

/*button:hover {*/
/*    background-color: rgba(51, 51, 51, 0.025);*/

/*}*/
.button:hover > span {
    -webkit-animation: marquee 2s infinite normal linear;
    animation: marquee 2s infinite normal linear;
}

.button span {

    padding: 0 1em;
}

@-webkit-keyframes marquee {
    to {
        transform: translateX(-100%);
    }
}

@keyframes marquee {
    to {
        transform: translateX(-100%);
    }
}


